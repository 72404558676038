require('../css/app.css');
const $ = require('jquery');
require('bootstrap');
require('slick-carousel');
const Cookies = require('js-cookie');

window.onSubmit = function (token) {
    document.getElementById("contact-form").submit();
}

$(document).on('submit', 'form', function () {
    $(this).find('[type="submit"]').attr('disabled', true);
});
$(function () {
    $('#slide').slick({
        autoplay: true,
        autoplaySpeed: 10000
    });
});